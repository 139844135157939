import React from "react"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import Tile from "../components/Tile"
import SEO from "../components/SEO"

const dodajInspiracje = () => {
  return (
    <Layout>
      <SEO
        title="Aggiungi la tua ispirazione"
        description="Compila il modulo e aggiungi il tuo suggerimento di stile o guarda il tutorial su come aggiungere ispirazione."
      />
      <Hero title="Aggiungi" bolded="styling"/>
      {/* <div className="container mx-auto flex justify-around items-center flex-wrap-reverse">
        <div>
          <h4 class="-textCenter">KONKURS</h4>
          <br/>
          <div>
          <ol>
              <li>1. Prześlij nam zdjęcie swojej walentynkowej stylizacji wraz z odpowiednim opisem oraz dopiskiem Praca konkursowa.</li>
              <li>2. Pamiętaj, że konkurs trwa od 5.02.2021 godz. 12:00 do 19.02.2021 godz. 12:00.</li>
              <li>3. W zakładce SPRAWDŹ INSPIRACJE do zakończenia konkursu zostaną opublikowane wybrane stylizacje, spośród których wyłonimy laureatów. </li>
              <li>4. Do zgarnięcia 6 kart podarunkowych, 1x200zł, 2x100zł i 3x50zł.</li>
              <li>5. Regulamin konkursu dostępny na: <a href="https://www.neonail.pl/konkurs-na-sklepie.html">https://www.neonail.pl/konkurs-na-sklepie.html</a></li>
              <li>6. Wyniki zostaną ogłoszone do dnia 24.02.2021 do godz. 12:00 na: <a href="https://www.neonail.pl/konkurs-na-sklepie.html">https://www.neonail.pl/konkurs-na-sklepie.html</a></li>
          </ol>
          </div>
          <br/>
        </div>
      </div> */}
      <div className="container mx-auto flex justify-around items-center flex-wrap-reverse">
        {/* <Form /> */}
        <iframe className="nnForm" id="nnForm" width="600" height="600" src="https://neonail.it/dodaj-inspiracje.html"/>
      </div>
    </Layout>
  )
}

export default dodajInspiracje
